import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { enUS, zhCN } from '@material-ui/core/locale'
import { themeCreator } from "./base";

const base = {
    palette: {
        type: "dark",
        common: {
            highlight: '#C90000',
            light: '#454545',
            dark: '#0E0E0E',
            middle: '#212121',
            black: '#000000'
        },
        primary: {
            main: "#FFFFFF"
        },
        secondary: {
            main: "#C90000"
        }
    },

    spacing: factor => `${0.125 * factor}rem`,
    typography: {
        textTransform: "none",
        navBar: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.75rem",
            color: "#FFFFFF",
        },
        metadata: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.625rem",
            color: "#999999",
        },
        menuBar: {
            fontFamily: `"Open Sans", Semibold`,
            fontWeight: '600',
            fontSize: "0.75rem",
            color: "#999999",
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        subMenuBar: {
            fontFamily: `"Open Sans", Semibold`,
            fontSize: "0.875rem",
            color: "#999999",
            '@media (max-width:600px)': {
                fontSize: '0.85rem',
            },
        },
        toggleMainMenu: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.7rem",
            color: "#999999",
        },
        filterBar: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.75rem",
            color: "#999999",
        },
        legends: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.75rem",
            color: "#FFFFFF",
        },
        content1: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.75rem",
            color: "#000000",
        },
        content2: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.75rem",
            color: "#FFFFFF",
        },
        contentHeader1: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.875rem",
            color: "#FFFFFF",
        },
        contentHeader3: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.75rem",
            color: "#999999",
        },
        contentHeader2: {
            fontFamily: `"Open Sans", Bold`,
            fontSize: "0.875rem",
            fontWeight: "600",
            color: "#000000",
        },
        contentTitle1: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.875rem",
            color: "#FFFFFF",
        },
        contentTitle2: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.875rem",
            color: "#000000",
        },
        contentIcon: {
            fontFamily: `"Open Sans", Bold`,
            fontSize: "0.625rem",
            color: "#FFFFFF",
        },
        profileHeading: {
            fontFamily: `"Open Sans", Bold`,
            fontSize: "0.875rem",
            color: "#FF9600",
        },
        profileClub: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.625rem",
            color: "#999999",
        },
        profileBalance1: {
            fontFamily: `"Open Sans", Regular`,
            fontSize: "0.875rem",
            color: "#999999",
        },
        profileBalance2: {
            fontFamily: `"Open Sans", Semibold`,
            fontSize: "0.875rem",
            color: "#FFFFFF",
        },
        button: {
            fontFamily: `"Open Sans", Semibold`,
            fontSize: "0.875rem",
            color: "#FFFFFF",
        },
        titleBar: {

        }

    },
}

const customTheme = {
    common: {
        screenContainer: {
            
            '@media (min-width:1240px)': {
                width: '71.25rem',
                height: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            '@media (max-width:1239px)': {
                width: '100%',
                maxWidth: '100%',
                height: '100%',
                marginLeft: 'auto',
                marginRight: 'auto'
            },
        }
    },
    navBar: {
        typo: base.typography.navBar
    },
    languageBar: {
        typo: base.typography.navBar
    },
    logo: {
        image: 'tajpoker_logo_1.png',
    },
    topPromoBanner: {
        images: [
            "top_promotional_banner_1.png"
        ]
    },
    menuBar: {
        typo: base.typography.menuBar
    },
    metadata: {
        valueTypo: base.typography.navBar,
        keyTypo: base.typography.metadata
    },
    subMenuBar: {
        typo: base.typography.subMenuBar
    },
    toggleMainMenu: {
        typo: base.typography.toggleMainMenu
    },
    bottomPromoBanner: {
        images: [
            "bottom_side_promotion_banner1.png"
        ]
    },
    filterBar: {
        typo: base.typography.filterBar,
        Root: {
            backgroundColor: base.palette.common.middle,
            // borderBottom: `1px groove grey`
        },
        Default: {
            backgroundColor: base.palette.common.light,
            borderRadius: base.spacing(2)
        },
        Selected: {
            backgroundColor: base.palette.common.highlight,
            borderRadius: base.spacing(2)
        },
        Advanced: {
            image: 'advancedfilter.svg'
        }
    },
    advancedfilter: {
        typo: {
            title: base.typography.contentTitle1,
            contentTitle: base.typography.contentTitle1,
            content: base.typography.navBar,
            contentSubTitle: base.typography.navBar,
            button: base.typography.button
        }
    },
    customGrid: {
        Header: {
            backgroundColor: base.palette.common.middle,
            borderTop: '1px groove grey',
            borderBottom: '1px groove grey'
        },
        RowStyleEven: {
            backgroundColor: base.palette.primary.main
        },
        RowStyleOdd: {
            backgroundColor: '#F0F0F0',
            borderBottom: '1px solid lightgray',
            borderTop: '1px solid lightgray'
        },
        HighlightedRow: {
            backgroundColor: base.palette.common.highlight
        },
        HighlightedRowCells: {
            color: base.palette.primary.main,
            textAlign: "center"
        },
        HeaderCells: {
            color: base.palette.primary.main,
            textAlign: 'center',
            borderLeft: '1px groove grey',
            borderRight: '1px groove grey',
            ...base.typography.contentHeader3
        },
        RowCells: {
            textAlign: 'center'
        },
        Action: {
            typo: base.typography.contentIcon,
            background: 'linear-gradient(180deg, #009600, #005200)',
            width: "40%",
            height: "75%",
            borderRadius: base.spacing(2)
        },
        Favorite: {
            iconFilled: {
                color: '#C90000',
            },
            iconHover: {
                color: '#ff3d47',
            },
            iconEmpty: {
                color: '#BEBEBE'
            }
        },
        Scroll: {
            rowHeight: '25px',
            backgroundColor: '#FFFFFF',
            loadingText: 'loading...',
            rowContainer: 'calc(70vh - 7rem)',
            padding: `${base.spacing(1)} 0 0 0`,
            tolerance: 500,
            enabled: false
        },
        RowColStyles: {
            0: {
                textAlign: "left"
            },
            1: {
                textAlign: "center"
            }
        }
    },
    legend: {
        typo: base.typography.legends
    },
    playerDetails: {
        Name: {
            color: '#FF9600'
        },
        Balances: {
            color: '#FFFFFF',
            fontSize: '13px',
        },
        ClubName: {
            color: '#FFFFFF',
            fontSize: '10px',
        },
        ClubDetails: {
            backgroundColor: '#000000',
            image: 'club.png'
        },
        Avatar: {
            backgroundColor: '#000000',
            image: 'avatar.png'
        },
        typo: {
            profileHeading: base.typography.profileHeading,
            balanceKey: base.typography.profileBalance1,
            balanceValue: base.typography.profileBalance2,
            club: base.typography.profileClub
        }
    },
    payment: {
        typo: base.typography.button,
        Cashier: {
            background: 'linear-gradient(180deg, #454545, #0E0E0E)',
            variant: 'button'
        },
        AddCash: {
            background: 'linear-gradient(180deg, #E50A17, #71020D)'
        }
    },
    detailView: {
        Root: {
            backgroundColor: '#FFFFFF'
        },
        customGrid: {
            Header: {
                backgroundColor: '#646464'
            },
            RowStyleEven: {
                backgroundColor: '#FFFFFF'
            },
            RowStyleOdd: {
                backgroundColor: '#efefef'
            },
            HeaderStyleOdd: {
                backgroundColor: '#454545',
                textAlign: 'center',
                color: '#FFFFFF'
            },
            HeaderStyleEven: {
                backgroundColor: '#646464',
                textAlign: 'center',
                color: '#FFFFFF'
            },
            HeaderCells: {
                ...base.typography.contentHeader3,
                color: base.palette.primary.main
            },
            RowCells: {
                textAlign: "center"
            },
            Pagination: {

                /* backgroundColor: "#454545",
                borderRadius: base.spacing(2) */
            },
            Scroll: {
                rowHeight: '25px',
                backgroundColor: '#FFFFFF',
                loadingText: 'loading...',
                rowContainer: '11.4rem',
                tolerance: 10,
                enabled: false
            },
            RowColStyles: {
                0: {
                    textAlign: "left"
                },
                1: {
                    textAlign: "left"
                }
            }
        },
        JoinTable: {
            background: 'linear-gradient(#454545, #0e0e0e)',
            borderRadius: base.spacing(2)
        },
        JoinWaitingTable: {
            background: 'linear-gradient(#009600, #005200)',
            borderRadius: base.spacing(2)
        },
        typo: {
            gameContentHeader: base.typography.contentHeader1,
            tableContentHeader: base.typography.contentHeader2,
            button: base.typography.button,
            pagination: base.typography.contentHeader3
        }
    },
    tournament: {
        statusColor: {
            1: '#009600',
            2: '#999999',
            3: '#D50000',
            4: '#FEBE0C',
            5: '#DBDBDB',
            6: '#FEBE0C'
        }
    }
}

export let defaultTheme = createMuiTheme(base, enUS, customTheme);

/* defaultTheme = responsiveFontSizes(defaultTheme); */

defaultTheme.overrides = {
    MuiCssBaseline: {
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.5rem',
                height: '0.75rem'
            },
            '*::-webkit-scrollbar-track': {
                // borderRadius: '4px',
                backgroundColor: '#5D5D5D',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, .4)',
                borderRadius: '3px',
                // border: '2px solid #ffffff'
            }
        }
    },
    MuiButton: {
        root: {
            borderRadius: 0,
            textTransform: 'none',
            padding: '0px'
        }
    },
    MuiFormControl: {
        root: {
            padding: "none"
        }
    }
    /*MuiListItem: {
        gutters: {
            paddingLeft: '8px',
            paddingRight: '8px'
        }
    },
    MuiFormControl: {
        root: {
            maxHeight: 'inherit'
        }
    } */

}